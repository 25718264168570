export default {
  'slot1': "2257946682",
  'slot2': "4939950349",
  'slot3': "1078411163",
  'firebaseConfig': {
apiKey: "AIzaSyAXgIxk1rYQpWOm8HOLYqOUgm4hqpFT4rQ",
  authDomain: "news7-network.firebaseapp.com",
  projectId: "news7-network",
  storageBucket: "news7-network.appspot.com",
  messagingSenderId: "738154200644",
  appId: "1:738154200644:web:a72e8d3976f3e19c014611",
  measurementId: "G-ZZ2Q2C9Y88"
  }
}